import { request } from "../services/axiosService";
import store from "../store";

// Login
export const login = async (username, password) => {
  const route = "/token/";
  const body = {
    username,
    password,
  };
  return request("api", "post", false, route, null, body);
};

// Version
export const getVersion = async () => {
  const route = "/version/";
  return request("api", "get", false, route);
};

// Users
export const postUser = async (
  password,
  username,
  first_name,
  last_name,
  email,
  is_staff
) => {
  const route = "/users/";
  const body = {
    password,
    username,
    first_name,
    last_name,
    email,
    is_staff,
  };
  return request("api", "post", true, route, null, body);
};

export const getUsers = async () => {
  const route = "/users/";

  const query = {
    ordering: "-id",
    //admin: filter.admin === "" ? undefined : filter.admin,
  };

  return request("api", "get", true, route, query);
};

export const getUser = async (id) => {
  const route = "/users/" + id + "/";

  return request("api", "get", true, route);
};

export const updateUser = async (
  id,
  password,
  username,
  first_name,
  last_name,
  email,
  is_staff
) => {
  const route = "/users/" + id + "/";

  const body = {
    password,
    username,
    first_name,
    last_name,
    email,
    is_staff,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteUser = async (id) => {
  const route = "/users/" + id + "/";
  return request("api", "delete", true, route);
};

// Addresses
export const postAddress = async (number, street, city, zipcode) => {
  const route = "/addresses/";
  const body = {
    number,
    street,
    city,
    zipcode,
  };
  return request("api", "post", true, route, null, body);
};

export const getAddresses = async (/*filter*/) => {
  const route = "/addresses/";

  const query = {
    ordering: "-id",
  };

  return request("api", "get", true, route, query);
};

export const getAddresse = async (id) => {
  const route = "/addresses/" + id + "/";

  return request("api", "get", true, route);
};

export const updateAddresse = async (id, number, street, city, zipcode) => {
  const route = "/addresses/" + id + "/";

  const body = {
    number,
    street,
    city,
    zipcode,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteAddresse = async (id) => {
  const route = "/addresses/" + id + "/";
  return request("api", "delete", true, route);
};

// Contact
export const postContact = async (
  first_name,
  last_name,
  phone_number,
  second_phone_number,
  email,
  position,
  agency_id,
  promoter_id,
  max_callback_date
) => {
  const route = "/contacts/";
  const body = {
    first_name,
    last_name,
    phone_number,
    second_phone_number,
    email,
    position,
    agency_id: agency_id === "" ? undefined : agency_id,
    promoter_id,
    max_callback_date,
  };
  return request("api", "post", true, route, null, body);
};

export const getContacts = async (filter) => {
  const route = "/contacts/";

  const query = {
    ordering: "-id",
    search: filter.search === "" ? undefined : filter.search,
    fields:
      "id,first_name,last_name,phone_number,email,position,last_followup_date,promoter__name,agency__name,last_followup_author,relation_quality",
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
    page: filter.page === "" ? undefined : filter.page,
  };

  return request("api", "get", true, route, query);
};

export const getContactsInPermit = async () => {
  const route = "/contacts/";

  const query = {
    ordering: "-id",
    fields: "id,first_name,last_name",
  };

  return request("api", "get", true, route, query);
};

export const getContact = async (id) => {
  const route = "/contacts/" + id + "/";

  return request("api", "get", true, route);
};

export const getContactFollowUps = async (id) => {
  const route = "/followups/?contact=" + id;

  return request("api", "get", true, route);
};

export const followUpsWithoutPermit = async (id) => {
  const route = "/followups/?contact=" + id + "&permit__isnull=true";

  return request("api", "get", true, route);
};

export const followUpsWithPermit = async (id) => {
  const route = "/followups/?contact=" + id + "&permit__isnull=false";

  return request("api", "get", true, route);
};

export const getContactCallbackDates = async (id) => {
  const route =
    "/callbackdates/?contact=" + id + "&user=" + store.state.userAuth.user_id;

  return request("api", "get", true, route);
};

export const deleteCallbackDate = async (id) => {
  const route = "/callbackdates/" + id + "/";

  return request("api", "delete", true, route);
};

export const getContactPermits = async (filter) => {
  const route = "/permits/";

  const query = {
    contacts__id: filter.id,
    ordering: filter.order === "" ? "-date_reelle_autorisation" : filter.order,
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
  };

  return request("api", "get", true, route, query);
};

export const updateContact = async (
  id,
  first_name,
  last_name,
  phone_number,
  second_phone_number,
  email,
  position,
  promoter,
  agency_id
) => {
  const route = "/contacts/" + id + "/";

  const body = {
    first_name,
    last_name,
    phone_number,
    second_phone_number,
    email,
    position,
    promoter,
    agency_id: agency_id === "" ? undefined : agency_id,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteContact = async (id) => {
  const route = "/contacts/" + id + "/";
  return request("api", "delete", true, route);
};

export const rateContact = async (id, relation_quality) => {
  const route = "/contacts/" + id + "/";

  const body = {
    relation_quality,
  };

  return request("api", "patch", true, route, null, body);
};

// Call back dates
export const getCallBackDates = async (filter) => {
  const route = "/callbackdates/?user=" + store.state.userAuth.user_id;

  const query = {
    ordering: filter.order === "" ? "-id" : filter.order,
    contact__permit__specific_case__in:
      filter.specific_case__in === "" ? "" : filter.specific_case__in,
  };

  return request("api", "get", true, route, query);
};

// Permits
export const getPermits = async (filter) => {
  const route = "/permits/";

  const query = {
    ordering: filter.order === "" ? "-date_reelle_autorisation" : filter.order,
    adr_localite_ter:
      filter.adr_localite_ter === "" ? undefined : filter.adr_localite_ter,
    city__decote_prix_ancien__gt:
      filter.city__decote_prix_ancien__gt === ""
        ? undefined
        : filter.city__decote_prix_ancien__gt,
    city__decote_prix_ancien__lt:
      filter.city__decote_prix_ancien__lt === ""
        ? undefined
        : filter.city__decote_prix_ancien__lt,
    date_reelle_autorisation__gte:
      filter.date_reelle_autorisation__gte === ""
        ? undefined
        : filter.date_reelle_autorisation__gte,
    date_reelle_autorisation__lte:
      filter.date_reelle_autorisation__lte === ""
        ? undefined
        : filter.date_reelle_autorisation__lte,
    date_reelle_daact__isnull:
      filter.date_reelle_daact__isnull === ""
        ? undefined
        : filter.date_reelle_daact__isnull,
    date_reelle_doc__isnull:
      filter.date_reelle_doc__isnull === ""
        ? undefined
        : filter.date_reelle_doc__isnull,
    dep__in:
      filter.dep__in === "" && filter.dep === ""
        ? undefined
        : filter.dep !== "" && filter.dep__in === ""
        ? filter.dep
        : filter.dep !== "" && filter.dep__in !== ""
        ? filter.dep + "," + filter.dep__in
        : filter.dep__in,
    etat_dau: filter.etat_dau === "" ? undefined : filter.etat_dau,
    localite_dem: filter.localite_dem === "" ? undefined : filter.localite_dem,
    nb_lgt_tot_crees__gte:
      filter.nb_lgt_tot_crees__gte === false
        ? undefined
        : filter.nb_lgt_tot_crees__gte,
    nb_lgt_tot_crees__lte:
      filter.nb_lgt_tot_crees__lte === false
        ? undefined
        : filter.nb_lgt_tot_crees__lte,
    nb_lls__gte: filter.nb_lls__gte === false ? undefined : filter.nb_lls__gte,
    nb_lls__lte: filter.nb_lls__lte === false ? undefined : filter.nb_lls__lte,
    nb_lgt_acc_soc_hors_ptz__gte:
      filter.nb_lgt_acc_soc_hors_ptz_gte === false
        ? undefined
        : filter.nb_lgt_acc_soc_hors_ptz_gte,
    nb_lgt_acc_soc_hors_ptz__lte:
      filter.nb_lgt_acc_soc_hors_ptz_lte === false
        ? undefined
        : filter.nb_lgt_acc_soc_hors_ptz_lte,
    nb_lls__gt: filter.nb_lls__gt === false ? undefined : filter.nb_lls__gt,
    nb_lls__lt: filter.nb_lls__lt === false ? undefined : filter.nb_lls__lt,
    referrers: filter.referrers === false ? undefined : filter.referrers,
    search: filter.search === "" ? undefined : filter.search,
    siren_dem__isnull:
      filter.siren_dem__isnull === "" ? undefined : filter.siren_dem__isnull,
    specific_case:
      filter.specific_case === "" ? undefined : filter.specific_case,
    specific_case__in:
      filter.specific_case__in === "" ? undefined : filter.specific_case__in,
    type_dau: filter.type_dau === "" ? undefined : filter.type_dau,
    referrers: filter.referrers === "" ? undefined : filter.referrers,
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
    page: filter.page === "" ? undefined : filter.page,
  };

  return request("api", "get", true, route, query);
};

export const getPermit = async (id) => {
  const route = "/permits/" + id + "/";

  return request("api", "get", true, route);
};

export const searchPermit = async (input) => {
  const route = "/permits/?search=" + input;

  return request("api", "get", true, route);
};

export const searchContact = async (input) => {
  const route = "/contacts/?fields=id,first_name,last_name&search=" + input;

  return request("api", "get", true, route);
};

export const searchAddress = async (input) => {
  const route = "/addresses/?search=" + input;

  return request("api", "get", true, route);
};

export const getPermitContacts = async (id) => {
  const route = "/contacts/?permit=" + id;

  return request("api", "get", true, route);
};

export const getPermitFollowUps = async (id) => {
  const route = "/followups/?permit=" + id;

  const query = {
    ordering: "-id",
  };

  return request("api", "get", true, route, query);
};

export const updatePermit = async (id, contacts) => {
  const route = "/permits/" + id + "/";

  const body = {
    contacts,
  };

  return request("api", "patch", true, route, null, body);
};

export const updatePromoterContact = async (promoter, contact_id) => {
  const route = "/contacts/" + contact_id + "/";

  const body = {
    promoter_id: promoter.id,
  };

  return request("api", "patch", true, route, null, body);
};

export const updatePermitPrincipalContact = async (id, principal_contact) => {
  const route = "/permits/" + id + "/";
  const body = {
    principal_contact,
  };

  return request("api", "patch", true, route, null, body);
};

export const updatePermitProspection = async (
  id,
  owner,
  discussion_stage,
  specific_case,
  referrers
) => {
  const route = "/permits/" + id + "/";
  const body = {
    owner: owner === "" ? undefined : owner,
    referrers: referrers === "" ? undefined : referrers,
    discussion_stage: discussion_stage === "" ? undefined : discussion_stage,
    specific_case: specific_case === "" ? undefined : specific_case,
  };

  return request("api", "patch", true, route, null, body);
};

export const deletePermit = async (id) => {
  const route = "/permits/" + id + "/";
  return request("api", "delete", true, route);
};

export const getPermitsInSelect = async () => {
  const route = "/permits/";
  const query = {
    ordering: "-id",
    fields:
      "id,num_dau,adr_num_ter,adr_typevoie_ter,adr_libvoie_ter,adr_localite_ter,nb_lgt_tot_crees,nb_lls,date_reelle_autorisation",
  };
  return request("api", "get", true, route, query);
};

// Follow ups
export const postFollowUp = async (contact, message, permit) => {
  const route = "/followups/";
  const body = {
    contact: contact === "" ? undefined : contact,
    message,
    permit: permit === "" ? undefined : permit,
    author:
      store.state.userAuth.user_id === ""
        ? undefined
        : store.state.userAuth.user_id,
  };
  return request("api", "post", true, route, null, body);
};

export const updateFollowUp = async (id, message) => {
  const route = "/followups/" + id + "/";

  const body = {
    message,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteFollowUp = async (id) => {
  const route = "/followups/" + id + "/";
  return request("api", "delete", true, route);
};

// Call back dates
export const postCallbackDate = async (contact_id, value, user) => {
  const route = "/callbackdates/";
  const body = {
    contact_id,
    value,
    user,
  };
  return request("api", "post", true, route, null, body);
};

export default {
  login,
  // Users
  postUser,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  // Version
  getVersion,
  //Addresses
  postAddress,
  getAddresses,
  getAddresse,
  updateAddresse,
  deleteAddresse,
  // Contacts
  postContact,
  getContacts,
  getContactsInPermit,
  getContactPermits,
  getContact,
  getContactFollowUps,
  followUpsWithoutPermit,
  followUpsWithPermit,
  getContactCallbackDates,
  updateContact,
  deleteContact,
  rateContact,
  // Permits
  getPermits,
  getPermit,
  searchPermit,
  searchContact,
  searchAddress,
  getPermitContacts,
  getPermitFollowUps,
  updatePermit,
  updatePromoterContact,
  updatePermitPrincipalContact,
  updatePermitProspection,
  deletePermit,
  getPermitsInSelect,
  // Follow ups
  postFollowUp,
  updateFollowUp,
  deleteFollowUp,
  // Call back dates
  getCallBackDates,
  postCallbackDate,
  deleteCallbackDate,
};
