import { request } from "../services/axiosService";
import store from "../store";

// Cities
export const postCity = async (name) => {
  const route = "/cities/";
  const body = {
    name,
  };
  return request("api", "post", true, route, null, body);
};

export const getCities = async (filter) => {
  const route = "/cities/";
  const query = {
    ordering: filter.order === "" ? undefined : filter.order,
    search: filter.search === "" ? undefined : filter.search,
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
    page: filter.page === "" ? undefined : filter.page,
    department: filter.department === "" ? undefined : filter.department,
    decote_prix_ancien__gte:
      filter.decote_prix_ancien__gte === ""
        ? undefined
        : filter.decote_prix_ancien__gte,
    decote_prix_ancien__lte:
      filter.decote_prix_ancien__lte === ""
        ? undefined
        : filter.decote_prix_ancien__lte,
    taux_lls_commune__gte:
      filter.lls__gte === "" ? undefined : filter.taux_lls_commune__gte,
    taux_lls_commune__lte:
      filter.lls__lte === "" ? undefined : filter.taux_lls_commune__lte,
    political_orientation:
      filter.political_orientation === ""
        ? undefined
        : filter.political_orientation,
    is_1loyermoinscher_customer:
      filter.is_1loyermoinscher_customer === ""
        ? undefined
        : filter.is_1loyermoinscher_customer,
    is_preemption_customer:
      filter.is_preemption_customer === ""
        ? undefined
        : filter.is_preemption_customer,
    mayor_relation_quality__in:
      filter.mayor_relation_quality__in === ""
        ? undefined
        : filter.mayor_relation_quality__in,
    already_implemented:
      filter.already_implemented === ""
        ? undefined
        : filter.already_implemented,
  };

  return request("api", "get", true, route, query);
};

export const getCity = async (id) => {
  const route = "/cities/" + id + "/";

  return request("api", "get", true, route);
};

export const searchCity = async (input) => {
  const route = "/cities/?search=" + input;
  return request("api", "get", true, route);
};

export const updateCity = async (form) => {
  const route = "/cities/" + form.id + "/";

  const body = {
    mayor_name: form.mayor_name !== "" ? form.mayor_name : undefined,
    mayor_position:
      form.mayor_position !== "" ? form.mayor_position : undefined,
    political_orientation:
      form.political_orientation !== ""
        ? form.political_orientation
        : undefined,
    is_1loyermoinscher_customer:
      form.is_1loyermoinscher_customer !== ""
        ? form.is_1loyermoinscher_customer
        : undefined,
    is_preemption_customer:
      form.is_preemption_customer !== ""
        ? form.is_preemption_customer
        : undefined,
    has_patrimoine_sur_la_commune:
      form.has_patrimoine_sur_la_commune !== ""
        ? form.has_patrimoine_sur_la_commune
        : undefined,
    election_date: form.election_date !== "" ? form.election_date : undefined,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteCity = async (id) => {
  const route = "/cities/" + id + "/";
  return request("api", "delete", true, route);
};

export const getCityPositions = async () => {
  const route = "/cities-positions/";

  return request("api", "get", true, route);
};

export const rateCity = async (form) => {
  const route = "/cities/" + form.id + "/";

  const body = {
    mayor_relation_quality: form.rating !== "" ? form.rating : undefined,
  };

  return request("api", "patch", true, route, null, body);
};

// Cities contacts
export const getCityContacts = async (id) => {
  const route = "/cities-contacts/?city=" + id;

  return request("api", "get", true, route);
};

export const getCitiesContacts = async (filter) => {
  const route = "/cities-contacts/";

  const query = {
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
    page: filter.page === "" ? undefined : filter.page,
    city: filter.city === "" ? undefined : filter.city,
  };
  return request("api", "get", true, route, query);
};

export const postCityContact = async (form) => {
  const route = "/cities-contacts/";

  const body = {
    first_name: form.first_name !== "" ? form.first_name : undefined,
    last_name: form.last_name !== "" ? form.last_name : undefined,
    phone_number: form.phone_number !== "" ? form.phone_number : undefined,
    email: form.email !== "" ? form.email : undefined,
    position: form.position !== "" ? form.position : undefined,
    owners: form.owners.length !== 0 ? form.owners : [],
    city: form.city_id,
    relation_quality: form.rating !== "" ? form.rating : undefined,
  };

  return request("api", "post", true, route, null, body);
};

export const updateCityContact = async (form) => {
  const route = "/cities-contacts/" + form.id + "/";

  const body = {
    first_name: form.first_name !== "" ? form.first_name : undefined,
    last_name: form.last_name !== "" ? form.last_name : undefined,
    phone_number: form.phone_number !== "" ? form.phone_number : undefined,
    email: form.email !== "" ? form.email : undefined,
    position: form.position !== "" ? form.position : undefined,
    owners: form.owners.length !== 0 ? form.owners : [],
    city: form.city_id,
    relation_quality: form.rating !== "" ? form.rating : undefined,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteCityContact = async (id) => {
  const route = "/cities-contacts/" + id + " ";

  return request("api", "delete", true, route);
};

// Cities callback dates
export const postCityCallbackDate = async (city, value, owner) => {
  const route = "/cities-callbackdates/";
  const body = {
    city,
    value,
    owner: owner == "" ? store.state.userAuth.user_id : owner,
  };
  return request("api", "post", true, route, null, body);
};

export const getCityCallbackDates = async (id) => {
  const route = "/cities-callbackdates/?city=" + id;

  return request("api", "get", true, route);
};

export const deleteCityCallbackDate = async (id) => {
  const route = "/cities-callbackdates/" + id + "/";

  return request("api", "delete", true, route);
};

export const getCitiesCallbackDates = async (filter) => {
  const route = "/cities-callbackdates/";

  const query = {
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
    page: filter.page === "" ? undefined : filter.page,
    ordering: filter.order === "" ? undefined : filter.order,
    owner:
      store.state.userAuth.user_id === ""
        ? undefined
        : store.state.userAuth.user_id,
  };
  return request("api", "get", true, route, query);
};

// Cities comments
export const postCityComment = async (city, content, author) => {
  const route = "/cities-comments/";
  const body = {
    city,
    content,
    author: author == "" ? store.state.userAuth.user_id : author,
  };
  return request("api", "post", true, route, null, body);
};

export const getCityComments = async (id) => {
  const route = "/cities-comments/?city=" + id;

  return request("api", "get", true, route);
};

export const deleteCityComment = async (id) => {
  const route = "/cities-comments/" + id + "/";

  return request("api", "delete", true, route);
};

export default {
  // Cities
  postCity,
  getCities,
  getCity,
  searchCity,
  updateCity,
  deleteCity,
  getCityPositions,
  rateCity,
  // Cities contacts
  getCityContacts,
  getCitiesContacts,
  postCityContact,
  updateCityContact,
  deleteCityContact,
  // Cities callback dates
  postCityCallbackDate,
  getCityCallbackDates,
  deleteCityCallbackDate,
  getCitiesCallbackDates,
  // City comments
  postCityComment,
  getCityComments,
  deleteCityComment,
};
