export default {
  userAuth: null,
  message: null,
  // Addresses
  address: null,
  addresses: null,
  // Cities
  citiesFilter: {
    department: "",
    decote_prix_ancien__gt: "",
    decote_prix_ancien__lt: "",
  },
  cityContacts: null,
  cityPositions: null,
  citiesContacts: null,
  cities: null,
  city: null,
  cityCallbackDates: null,
  cityComments: null,
  citiesCallbackDates: null,
  // Agencies
  agencyContacts: null,
  agencyPermits: null,
  // Contacts
  contact: null,
  contacts: null,
  contactCallbackDates: null,
  contactPermits: null,
  // Callback dates
  callBackDates: null,
  callBackDatesNegotiation: null,
  callBackDatesFavoris: null,
  callBackDatesLeft: null,
  // Permits
  permit: null,
  permits: null,
  permitsFilter: {
    adr_localite_ter: "",
    date_reelle_autorisation__gte: "",
    date_reelle_autorisation__lte: "",
    date_reelle_daact__isnull: "",
    date_reelle_doc__isnull: "",
    dep: "",
    dep__in: "",
    city__decote_prix_ancien__gt: "",
    city__decote_prix_ancien__lt: "",
    etat_dau: "",
    localite_dem: "",
    nb_lgt_tot_crees__gte: "",
    nb_lgt_tot_crees__lte: "",
    nb_lls__gte: "",
    nb_lls__lte: "",
    nb_lls__gt: "",
    nb_lls__lt: false,
    num_dau: "",
    order: "",
    owner: false,
    referrers: [],
    specific_case: "",
    specific_case__in: "",
    type_dau: "",
  },
  permitContacts: null,
  permitFollowUps: null,
  // Promoters
  promoter: null,
  promoters: null,
  promoterPermits: null,
  // Users
  users: null,
  user: null,
};
